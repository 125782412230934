import React from 'react';
import IStrapiImage from '../../interfaces/IStrapiImage';
import HighSubheading from '../HighSubheading';
import Inner from '../Inner';
import StrapiImage from '../StrapiImage';
import { Section, Item, ImageWrapper, Text, Wrapper, MessageWrapper, Flex } from './styles';

interface Props { 
  message: string
  items: {
    text: string 
    icon: IStrapiImage
  }[]
}

const IndexFeatures : React.FC<Props> = props => {

  const {
    message,
    items,
  } = props;

  return (
    <Section id="section-features">
      <Inner>
        <Wrapper>
          <MessageWrapper>
            <HighSubheading
              text={message}
            />
          </MessageWrapper>
          <Flex>
            {items.map((item, index) => {
              const { text, icon } = item; 

              return (
                <Item key={index}>
                  <ImageWrapper>
                    <StrapiImage
                      image={icon}
                      nativeImageHeight={58}
                      nativeImageWidth={58}
                    />
                  </ImageWrapper>
                  <Text
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                </Item>
              )
            })}
          </Flex>
        </Wrapper> 
      </Inner>
    </Section>
  );
}

export default IndexFeatures;