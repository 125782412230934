import styled from 'styled-components';
import { media } from '../../common/MediaQueries';

export const Section = styled.div`
  padding-top: 40px;
  padding-bottom: 42px; 

  @media ${media.phone} { 
    padding-top: 0;
    padding-bottom: 72px; 
  }
`

export const Wrapper = styled.div`
  padding-top: 20px;

  @media ${media.tablet} { 
    padding-top: 70px;
  }
`

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 400px;
  margin: 0 auto; 
  margin-top: 40px;

  @media ${media.tablet} { 
    margin: 0 auto;
    max-width: 1200px;
    margin-top: 60px;
  }
`

export const Item = styled.div`
  width: 100%;
  padding: 30px 0;

  @media ${media.tablet} { 
    width: 20%;
    flex-direction: column;
    align-items: flex-start; 
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const ImageWrapper = styled.div`
  height: 58px;
  width: 58px;
  margin: 0 auto;
  flex-shrink: 0;

  & img { 
    max-width: 100%; 
    max-height: 100%;
  }
`

export const Text = styled.div`
  text-align: left; 
  font-size: 0.875rem; 
  leading: 1.2;
  margin-top: 20px;
  text-align: center;

  @media ${media.phone} { 
    font-size: 1rem; 
  }

  @media ${media.tablet} { 
    margin-top: 20px; 
    padding-left: 0;
  }
`

export const MessageWrapper = styled.div`
  max-width: 800px;

  @media ${media.tablet} { 
    max-width: 100%;
  }
`